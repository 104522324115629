import * as dayjs from 'dayjs';
import { PLAN_TAX } from './plan';
var CAMPAIGN_PLAN_AMOUNT = 1980;
var CoachingAmazonPayPlan = /** @class */ (function () {
    function CoachingAmazonPayPlan(coachingPlanData) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "planId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "planType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "idempotencyKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "renewalDayFromNow", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = coachingPlanData.id;
        this.planId = coachingPlanData.planId;
        this.name = coachingPlanData.name;
        this.amount = coachingPlanData.amount;
        this.planType = coachingPlanData.planType;
        this.idempotencyKey = coachingPlanData.idempotencyKey;
        this.renewalDayFromNow = dayjs.unix(coachingPlanData.renewalDayFromNow);
    }
    Object.defineProperty(CoachingAmazonPayPlan.prototype, "isStandardPlan", {
        get: function () {
            return this.planType === 'standard';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CoachingAmazonPayPlan.prototype, "isCampaignPlan", {
        get: function () {
            return this.amount === CAMPAIGN_PLAN_AMOUNT;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CoachingAmazonPayPlan.prototype, "excludeTaxAmount", {
        get: function () {
            return this.amount / PLAN_TAX;
        },
        enumerable: false,
        configurable: true
    });
    return CoachingAmazonPayPlan;
}());
export { CoachingAmazonPayPlan };
