var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { CloseIcon, Button, ButtonType, ArrowIcon, ArrowDirection, LineButton } from '@lean-body/components/atoms';
import { FooterComponent } from '../organisms';
import { coachingDialogs } from '../pages/main_coaching';
import { CoachingExplanation } from '../atoms/coaching_explanation';
var CLOSE_ICON_SIZE = 15;
var doneCheckPath = '/assets/images/coaching/done_check.png';
export var CoachingDialogContent = function (_a) {
    var coachingPlan = _a.coachingPlan, coachingAmazonPayPlan = _a.coachingAmazonPayPlan, openDialog = _a.openDialog, onClickCancel = _a.onClickCancel, onClickBackNotSubscribed = _a.onClickBackNotSubscribed, onClickStartSubscription = _a.onClickStartSubscription, onClickRedirectLine = _a.onClickRedirectLine, submitDisabled = _a.submitDisabled, cardLastFour = _a.cardLastFour;
    var ref = React.useRef(null);
    var planPrefix;
    var planName;
    var planAmount;
    var excludeTaxAmount;
    var renewalDay;
    var message;
    if (coachingPlan) {
        planName = coachingPlan.name;
        planAmount = coachingPlan.amount.toLocaleString();
        excludeTaxAmount = coachingPlan.excludeTaxAmount.toLocaleString();
        planPrefix = coachingPlan.isStandardPlan ? '月額' : null;
        renewalDay = coachingPlan.renewalDayFromNow.format('YYYY年MM月DD日');
        message = "\u524D\u56DE\u3054\u5229\u7528\u306E\u30AF\u30EC\u30B8\u30C3\u30C8\u30AB\u30FC\u30C9****".concat(cardLastFour, "\u3067\u6C7A\u6E08\u3055\u308C\u307E\u3059");
    }
    else {
        planName = coachingAmazonPayPlan.name;
        planAmount = coachingAmazonPayPlan.amount.toLocaleString();
        excludeTaxAmount = coachingAmazonPayPlan.excludeTaxAmount.toLocaleString();
        planPrefix = coachingAmazonPayPlan.isStandardPlan ? '月額' : null;
        renewalDay = coachingAmazonPayPlan.renewalDayFromNow.format('YYYY年MM月DD日');
        message = '前回ご利用のAmazonPayで決済されます';
    }
    React.useEffect(function () {
        if (ref.current !== null) {
            ref.current.scrollTop = 0;
        }
    }, [openDialog]);
    return (React.createElement("div", { className: css(styles.contentContainer) },
        React.createElement("div", { className: css(styles.headerContainer) },
            openDialog === coachingDialogs.startSubscription ? (React.createElement(ArrowIcon, { color: Colors.Black, classObject: styles.closeIconContainer, direction: ArrowDirection.left, onClick: onClickBackNotSubscribed })) : (React.createElement(CloseIcon, { color: Colors.Black, size: CLOSE_ICON_SIZE, containerClassObject: styles.closeIconContainer, onClick: onClickCancel })),
            React.createElement("div", { className: css(styles.headerTitle) }, "\u304A\u7533\u3057\u8FBC\u307F\u624B\u7D9A\u304D")),
        React.createElement("div", { className: css(styles.scroll), ref: ref },
            React.createElement("div", { className: css(styles.bodyContainer) }, openDialog === coachingDialogs.startSubscription ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.planContainer) },
                    React.createElement("span", { className: css(styles.planName) }, planName),
                    React.createElement("br", null),
                    planPrefix,
                    ": ",
                    excludeTaxAmount,
                    "\u5186 ",
                    React.createElement("span", { className: css(styles.excludeTax) },
                        "(\u7A0E\u8FBC",
                        planAmount,
                        "\u5186)")),
                React.createElement("div", { className: css(styles.renewalDay) },
                    "\u66F4\u65B0\u65E5: ",
                    renewalDay),
                React.createElement(Button, { isRadius: true, buttonType: submitDisabled ? ButtonType.disabled : ButtonType.primary, className: css(styles.button), disabled: submitDisabled, loading: submitDisabled, onClick: onClickStartSubscription }, "\u652F\u6255\u3044\u3092\u78BA\u5B9A\u3059\u308B"),
                React.createElement("div", { className: css(styles.supplement) }, message))) : (
            // コーチング決済完了
            openDialog === coachingDialogs.startCompleted && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.messageBox) },
                    React.createElement("img", { className: css(styles.messageImage), src: doneCheckPath }),
                    React.createElement("div", { className: css(styles.message) }, "\u30D7\u30E9\u30F3\u767B\u9332\u304C\u5B8C\u4E86\u3057\u307E\u3057\u305F\uFF01")),
                React.createElement("div", { className: css(styles.zendeskMessage) },
                    "\u3054\u767B\u9332\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u306B\u767B\u9332\u5B8C\u4E86\u306E\u30E1\u30FC\u30EB\u3092\u9001\u4FE1\u3057\u307E\u3057\u305F\u3002\u30E1\u30FC\u30EB\u304C\u5C4A\u3044\u3066\u3044\u306A\u3044\u5834\u5408\u306F",
                    React.createElement("a", { className: css(styles.link), href: "https://wondernuts.zendesk.com/hc/ja/requests/new", target: "_blank" }, "\u3053\u3061\u3089"),
                    "\u307E\u3067\u304A\u554F\u3044\u5408\u308F\u305B\u304F\u3060\u3055\u3044"),
                React.createElement("div", null,
                    React.createElement(LineButton, { classObject: [styles.button, styles.lineButton], onClick: onClickRedirectLine }, "\u30B3\u30FC\u30C1\u306E\u30B5\u30DD\u30FC\u30C8\u3092\u53D7\u3051\u308B"),
                    React.createElement("div", { className: css(styles.supplement) }, "LINE\u3092\u958B\u304D\u307E\u3059")))))),
            (openDialog === coachingDialogs.startSubscription || openDialog === coachingDialogs.startCompleted) && (React.createElement(CoachingExplanation, { title: "\u30D7\u30E9\u30F3\u306E\u66F4\u65B0\u306B\u3064\u3044\u3066" },
                "\u203B\u81EA\u52D5\u66F4\u65B0\u3068\u306A\u308B\u305F\u3081\u3001\u7D99\u7D9A\u3092\u3054\u5E0C\u671B\u3055\u308C\u306A\u3044\u5834\u5408\u306F\u8A2D\u5B9A>\u3054\u767B\u9332\u4E2D\u306E\u30D7\u30E9\u30F3\u304B\u3089\u9000\u4F1A\u624B\u7D9A\u304D\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002",
                React.createElement("br", null),
                "\u203BLEAN BODY\u3092\u9000\u4F1A\u3057\u305F\u5834\u5408\u306F\u3001\u30B3\u30FC\u30C1\u30F3\u30B0\u6A5F\u80FD\u3082\u5229\u7528\u3067\u304D\u306A\u304F\u306A\u308A\u307E\u3059")),
            React.createElement("div", { className: css(styles.footerContainer) },
                React.createElement(FooterComponent, null)))));
};
var styles = StyleSheet.create({
    contentContainer: (_a = {
            background: Colors.White,
            height: '100%',
            padding: '10px 0 0',
            borderRadius: '20px 20px 0 0',
            textAlign: 'center'
        },
        _a[MediaBreakPointUp.MD] = {
            borderRadius: 20,
        },
        _a),
    headerContainer: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    closeIconContainer: {
        position: 'absolute',
        top: 15,
        left: 20,
        cursor: 'pointer',
    },
    headerTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        borderBottom: "1px solid ".concat(Colors.BorderLightGray),
        paddingBottom: 10,
    },
    scroll: (_b = {
            overflowY: 'scroll',
            height: 'auto',
            maxHeight: 'calc(100vh - 72px)'
        },
        _b[MediaBreakPointUp.MD] = {
            maxHeight: 'calc(100vh - 242px)',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '::-webkit-scrollbar': {
                display: 'none',
            },
        },
        _b),
    bodyContainer: {
        padding: '0 24px 30px',
        marginTop: 30,
    },
    messageBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    messageImage: {
        width: 58,
        height: 58,
        marginBottom: 20,
    },
    message: {
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: '1.44px',
    },
    supplement: {
        color: Colors.GrayDarkest,
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: '0.48px',
        marginTop: 8,
    },
    link: {
        color: Colors.Primary,
        textDecoration: 'underline',
    },
    button: {
        fontSize: 16,
        width: '100%',
    },
    lineButton: {
        backgroundColor: Colors.Line,
        borderColor: Colors.Line,
        color: Colors.White,
    },
    footerContainer: {
        paddingTop: 20,
        paddingBottom: 30,
    },
    planContainer: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    planName: {
        fontSize: 12,
    },
    excludeTax: {
        fontSize: 10,
    },
    renewalDay: {
        fontSize: 14,
        color: Colors.GrayDarkest,
        margin: '10px 0 16px',
        textAlign: 'center',
        letterSpacing: '0.04em',
        lineHeight: '140%',
    },
    zendeskMessage: {
        fontSize: 14,
        color: Colors.GrayDarkest,
        margin: '20px 0',
        textAlign: 'center',
        letterSpacing: '0.04em',
        lineHeight: '140%',
    },
});
